import { IconCheck } from '@troon/icons/check';
import { twJoin } from '@troon/tailwind-preset/merge';
import { children, Show } from 'solid-js';
import { Heading } from './text';
import { Section } from './container';
import type { ComponentProps, JSXElement, ParentProps } from 'solid-js';

type HeadingProps = ParentProps<{
	action?: JSXElement;
	step: number;
	state: 'completed' | 'current' | 'waiting';
}>;

export function SectionStepHeading(props: HeadingProps) {
	return (
		<div class="flex flex-row items-center gap-4">
			<div
				role="presentation"
				class={twJoin(
					'flex size-10 shrink-0 items-center justify-center rounded-full text-xl font-semibold',
					props.state === 'completed' && 'bg-brand-100 text-brand',
					props.state === 'current' && 'bg-brand text-white',
					props.state === 'waiting' && 'bg-neutral-300 text-neutral-700',
				)}
			>
				<Show when={props.state !== 'completed'} fallback={<IconCheck />}>
					{props.step.toString()}
				</Show>
			</div>
			<Heading as="h2" size="h4" class="grow leading-tight">
				{props.children}
			</Heading>
			{props.action}
		</div>
	);
}

type StepProps = Pick<ComponentProps<typeof Section>, 'appearance'> &
	ParentProps<{
		action?: JSXElement;
		step: number;
		state: 'completed' | 'current' | 'waiting';
		title: JSXElement;
		value?: JSXElement;
	}>;

export function SectionStep(props: StepProps) {
	const value = children(() => props.value);
	return (
		<Section appearance={props.appearance} layout="tight">
			<SectionStepHeading action={props.action} state={props.state} step={props.step}>
				{props.title}
			</SectionStepHeading>

			<Show when={props.state === 'completed' && value()}>
				<div class="ps-14">{value()}</div>
			</Show>
			<div class="flex flex-col gap-4" classList={{ hidden: props.state !== 'current' }}>
				{props.children}
			</div>
		</Section>
	);
}
